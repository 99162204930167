/* eslint-disable tailwindcss/no-custom-classname */
import Head from 'next/head'
import Resolver from '@components/MagentoPageBuilder/Resolver'
import { resolveUrl } from '@utils/helpers/resolveUrl'
import { useSelector } from 'react-redux'

import { useEffect, useState } from 'react'

const MPBBlockDynamic = ({ identifier }) => {
  const [contents, setContents] = useState(null)
  const [pageStyle, setPageStyle] = useState('')
  const country = useSelector((state) => state.store.country.code)
  const language = useSelector((state) => state.store.language.code)

  useEffect(() => {
    const fetchContent = async () => {
      const data = await resolveUrl(identifier, language, country)
      const content = data?.hits?.hits[0]?._source?.content || null
      if (content) {
        setContents(content)
        setPageStyle(content['inline-styles'] || '')
      }
    }
    fetchContent()
  }, [identifier, language, country])

  if (!contents) return null

  return (
    <>
      <Head>
        {pageStyle && (
          <style
            dangerouslySetInnerHTML={{ __html: pageStyle }}
            type="text/css"
          />
        )}
      </Head>
      <div className="mpb-block" data-pb-style={contents['data-pb-style']}>
        <Resolver content={[contents[0]] || []} />
      </div>
    </>
  )
}

export default MPBBlockDynamic
