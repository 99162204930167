import urlResolver from '@utils/urlResolver'

// Cache object for storing resolved URLs
const urlCache = new Map()
const CACHE_DURATION = 1000 * 60 * 15 // 15 minutes
const URL_TIMEOUT = 3000 // 3 seconds

/**
 * Resolves a URL based on slug and language with caching
 * @param {string} slug - The slug to resolve
 * @param {string} language - The language code
 * @returns {Promise<string|null>} - Resolved URL or null
 */
export async function resolveUrl(slug, language, country = null) {
  // Input validation
  if (!isValidInput(slug, language)) {
    console.warn('Invalid input parameters')
    return null
  }

  // Normalize slug
  const normalizedSlug = normalizeSlug(slug)

  // Generate cache key
  const cacheKey = `${normalizedSlug}-${language}${country ? `-${country}` : ''}`

  // Check cache
  const cachedResult = getCachedResult(cacheKey)
  if (cachedResult) return cachedResult

  try {
    // Attempt URL resolution with timeout
    const response = await Promise.race([
      urlResolver(normalizedSlug, language, country),
      new Promise((_, reject) =>
        setTimeout(
          () => reject(new Error('URL resolution timeout')),
          URL_TIMEOUT,
        ),
      ),
    ])

    // Cache successful result
    if (response) {
      setCacheResult(cacheKey, response)
    }

    return response
  } catch (error) {
    handleError(error)
    return null
  }
}

// Helper functions
function isValidInput(slug, language) {
  return (
    slug &&
    typeof slug === 'string' &&
    language &&
    typeof language === 'string' &&
    slug.length > 0 &&
    language.length > 0
  )
}

function normalizeSlug(slug) {
  return slug.trim().toLowerCase()
}

function getCachedResult(key) {
  const cached = urlCache.get(key)
  if (!cached) return null

  const { value, timestamp } = cached
  if (Date.now() - timestamp > CACHE_DURATION) {
    urlCache.delete(key)
    return null
  }

  return value
}

function setCacheResult(key, value) {
  urlCache.set(key, {
    value,
    timestamp: Date.now(),
  })
}

function handleError(error) {
  if (error.message === 'URL resolution timeout') {
    console.error('URL resolution timed out')
  } else {
    console.error('Error resolving URL:', error)
  }
}
