import dynamic from 'next/dynamic'

const contentTypesConfig = {
  block: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBBlock'),
  ),
  banner: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBBanner'),
  ),
  slider: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBSlider'),
  ),
  slide: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBSlide'),
  ),
  row: dynamic(() => import('@components/MagentoPageBuilder/blocks/MPBRow')),
  column: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBColumn'),
  ),
  'column-line': dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBColumn'),
  ),
  'column-group': dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBColumnGroup'),
  ),
  text: dynamic(() => import('@components/MagentoPageBuilder/blocks/MPBText')),
  html: dynamic(() => import('@components/MagentoPageBuilder/blocks/MPBText')),
  divider: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBDivider'),
  ),
  image: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBImage'),
  ),
  tabs: dynamic(() => import('@components/MagentoPageBuilder/blocks/MPBTabs')),
  buttons: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBButtons'),
  ),
  heading: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBHeading'),
  ),
  html: dynamic(() => import('@components/MagentoPageBuilder/blocks/MPBHtml')),
  map: dynamic(() => import('@components/MagentoPageBuilder/blocks/MPBMap')),
  video: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBVideo'),
  ),
  banner: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBBanner'),
  ),
  products: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBProducts'),
  ),
  formular: dynamic(
    () => import('@components/MagentoPageBuilder/blocks/MPBFormular'),
  ),
}

const renderContentType = (Component, content, index) => {
  if (
    !content['data-content-type'] ||
    (content['data-content-type'] &&
      !Object.keys(contentTypesConfig).includes(content['data-content-type']))
  ) {
    return <div key={index} />
  }
  if (typeof Component === 'string') {
    return (
      <div data-pb-style={content['data-pb-style']} key={index}>
        {Component}
      </div>
    )
  }
  return <Component content={content} key={index} />
}

const Resolver = ({ content }) => {
  if (!content[0] || !content[0]['data-content-type']) {
    return <div />
  }

  return content.map((item, index) => {
    const component = renderContentType(
      typeof contentTypesConfig[item['data-content-type']] !== 'undefined'
        ? contentTypesConfig[item['data-content-type']]
        : item['data-content-type'],
      item,
      index,
    )
    return component
  })
}

export default Resolver
