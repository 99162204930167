import { apiPost } from './api'
import { appConfig } from '@utils/appConfig'

// Constants for ElasticSearch configuration
const SOURCE_INCLUDES = [
  '*.children_data.id',
  'active',
  'average_review',
  'average_review_count',
  'bynder_images',
  'bynder_images_nested',
  'capacity',
  'category',
  'category_ids',
  'children_count',
  'children_data.children_count',
  'children_data.id',
  'children_data.lowest_price',
  'children_data.name',
  'children_data.position',
  'children_data.preview_image',
  'children_data.rank_order_mobile',
  'children_data.rank_order_shop',
  'children_data.url_path',
  'children_data.layer_navigation_attributes',
  'cms_content_page',
  'color',
  'configurable_children',
  'connection',
  'content',
  'content_heading',
  'content.*',
  'country_ids',
  'custom_layout_update_xml',
  'custom_options',
  'custom_root_template',
  'custom_theme_from',
  'custom_theme_to',
  'delivery_time_zero_stock',
  'deliverytime',
  'description',
  'display',
  'display_mode_akeneo',
  'exploded_view',
  'final_price',
  'final_price_incl_tax',
  'final_price_tax',
  'finalPrice',
  'finalPriceInclTax',
  'finalPriceTax',
  'form',
  'ggm_related_categories',
  'gross_depth',
  'gross_height',
  'gross_width',
  'gtin',
  'highest_price',
  'href_lang',
  'icon',
  'id',
  'identifier',
  'image',
  'include_in_menu',
  'interior_lighting',
  'is_active',
  'layout_update_selected',
  'layout_update_xml',
  'leasable',
  'level',
  'lowest_price',
  'manual',
  'manufacturer_dimensions',
  'material',
  'media_gallery',
  'menu_display_mode',
  'meta_description',
  'meta_keywords',
  'meta_title',
  'name',
  'net_dimensions',
  'new_article',
  'no_leasing',
  'number_of_drawers',
  'orbitvu_id',
  'orbitvu_script',
  'orbitvu_thumbnail',
  'original_final_price',
  'original_price',
  'original_price_incl_tax',
  'original_price_tax',
  'originalPrice',
  'originalPriceInclTax',
  'originalPriceTax',
  'page_layout',
  'parent_id',
  'path',
  'position',
  'power',
  'power_connection',
  'preview_image',
  'price',
  'price_incl_tax',
  'price_tax',
  'priceInclTax',
  'priceTax',
  'product_count',
  'product_label',
  'product_links',
  'quality_level',
  'rank_order_mobile',
  'rank_order_shop',
  'regular_price',
  'related_categories',
  'request_item',
  'request_path',
  'rrp',
  'sales_blacklist',
  'sales_price_at',
  'sales_price_be',
  'sales_price_bg',
  'sales_price_ch',
  'sales_price_cz',
  'sales_price_de',
  'sales_price_dk',
  'sales_price_es',
  'sales_price_fi',
  'sales_price_fr',
  'sales_price_gr',
  'sales_price_hu',
  'sales_price_ie',
  'sales_price_ir',
  'sales_price_it',
  'sales_price_lv',
  'sales_price_nl',
  'sales_price_nl_bl',
  'sales_price_no',
  'sales_price_pl',
  'sales_price_pt',
  'sales_price_ro',
  'sales_price_se',
  'sales_price_ua',
  'sales_price_uk',
  'second_choice',
  'shipping_method',
  'short_description',
  'show_category_filter',
  'layer_navigation_attributes',
  'show_on_storefront',
  'show_on_storefront_customers_recommend',
  'show_on_storefront_interesting_articles',
  'sku',
  'slug',
  'sort_order',
  'spare_list_products',
  'spareparts_list',
  'special_price',
  'special_price_incl_tax',
  'special_price_tax',
  'specialPrice',
  'specialPriceInclTax',
  'specialPriceTax',
  'status',
  'stock',
  'supply_date',
  'target_category',
  'target_path',
  'tax_class_id',
  'technical_description',
  'technical_videos_data',
  'thumbnail',
  'title',
  'tsk',
  'type_id',
  'updated_in',
  'upstand',
  'url_key',
  'url_path',
  'videos',
  'videos_data',
  'visibility',
  'website',
  'adobeaero_qr_export',
  'adobeaero_link',
  'energy_label_export',
  'energy_efficiency_class',
]

// Memoize the encoded source parameters for performance
const ENCODED_SOURCE_PARAMS = encodeURI(SOURCE_INCLUDES.join(','))

// Simple in-memory cache with TTL
const cache = new Map()
const CACHE_TTL = 15 * 60 * 1000 // 15 minutes in milliseconds

// Helper function to create cache key
const createCacheKey = (slug, locale) => `${slug}:${locale}`

// Helper function to check if cache entry is valid
const isCacheValid = (entry) => {
  return entry && Date.now() - entry.timestamp < CACHE_TTL
}

// Helper function to create optimized search criteria for URL resolution
const createSearchQuery = (slug, country = null) => {
  // Using object literal for better performance
  const query = {
    query: {
      bool: {
        should: [
          { term: { slug } },
          { term: { url_path: slug } },
          { term: { url_key: slug } },
          {
            term: { identifier: slug },
          },
          {
            term: { identifier: `${slug}${country ? `___${country}` : ''}` },
          },
          { term: { request_path: slug } },
          { term: { sku: slug } },
        ],
        minimum_should_match: 1, // Optimize query execution by specifying minimum matches
      },
    },
    // Add size limit for better performance
    size: 2, // Since we usually need only one result
  }
  return query
}

// Helper function to build ElasticSearch endpoint
const buildEndpoint = (elasticSearchIndex) => {
  return `catalog/${elasticSearchIndex}/*/_search?from=0&size=2&_source_include=${ENCODED_SOURCE_PARAMS}`
}

// Batch processing helper
const batchResolver = async (slugs, locale) => {
  if (!Array.isArray(slugs) || slugs.length === 0) {
    return []
  }

  const elasticSearchIndex = appConfig.elasticsearch[locale]
  if (!elasticSearchIndex) {
    throw new Error(`Invalid locale: ${locale}`)
  }

  // Create bulk query
  const searchQuery = {
    query: {
      bool: {
        should: slugs.map((slug) => ({
          bool: {
            should: [
              { term: { slug } },
              { term: { url_path: slug } },
              { term: { url_key: slug } },
              { term: { identifier: slug } },
              { term: { request_path: slug } },
              { term: { sku: slug } },
            ],
            minimum_should_match: 1,
          },
        })),
        minimum_should_match: 1,
      },
    },
    size: slugs.length,
  }

  const endpoint = buildEndpoint(elasticSearchIndex)
  return await apiPost(endpoint, JSON.stringify(searchQuery))
}

/**
 * Resolves URL based on slug and locale with improved performance
 * @param {string|string[]} slug - URL slug(s) to resolve
 * @param {string} locale - Locale for elasticsearch index
 * @returns {Promise} - Returns elasticsearch response
 */
const urlResolver = async (slug, locale, country = null) => {
  try {
    // Validate required parameters
    if (!slug || !locale) {
      throw new Error('Slug and locale are required parameters')
    }

    // Handle batch processing
    if (Array.isArray(slug)) {
      return await batchResolver(slug, locale)
    }

    // Check cache first
    const cacheKey = createCacheKey(slug, locale)
    const cachedResult = cache.get(cacheKey)
    if (isCacheValid(cachedResult)) {
      return cachedResult.data
    }

    const elasticSearchIndex = appConfig.elasticsearch[locale]
    if (!elasticSearchIndex) {
      throw new Error(`Invalid locale: ${locale}`)
    }

    // Prepare and execute search
    const searchQuery = createSearchQuery(slug, country)
    const endpoint = buildEndpoint(elasticSearchIndex)
    const result = await apiPost(endpoint, JSON.stringify(searchQuery))

    let data = result
    if (country) {
      const countryResult = result.hits.hits.filter(
        (item) => item?._source?.identifier === `${slug}___${country}`,
      )
      if (countryResult.length > 0) {
        data = { ...result, hits: { hits: countryResult } }
      }
    }

    // Cache the result
    cache.set(cacheKey, {
      data,
      timestamp: Date.now(),
    })

    return data
  } catch (error) {
    console.error('Error in urlResolver:', error.message)
    throw error
  }
}

// Cleanup expired cache entries periodically
setInterval(() => {
  for (const [key, entry] of cache.entries()) {
    if (!isCacheValid(entry)) {
      cache.delete(key)
    }
  }
}, CACHE_TTL)

export default urlResolver
